import React from 'react';

import type { Deal } from '../../types/Deal';
import { useHawkWidgetContext } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import MagazineSubscriptionsItem from './MagazineSubscriptionsItem';
import MagazineSubscriptionsMain from './MagazineSubscriptionsMain';

interface MagazineSubscriptionsMainWrapperProps {
  items: Deal[];
}

export const MagazineSubscriptionsMainWrapper: React.FC<MagazineSubscriptionsMainWrapperProps> = ({
  items,
}) => {
  const { dealData } = useHawkWidgetContext();
  const deals = dealData && dealData.deals ? dealData.deals : [];

  return (
    <MagazineSubscriptionsMain>
      {items.map((deal) => (
        <MagazineSubscriptionsItem
          deal={deal}
          deals={deals}
          key={items.map((deal) => deal?.id).join('')}
        />
      ))}
    </MagazineSubscriptionsMain>
  );
};
