import React from 'react';

import getProsCons from '../../modules/getProsCons';
import type { Deal } from '../../types/Deal';
import { Consumer } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import styles from './styles/magazinesubscriptions-pros.css';

interface MagazineSubscriptionsProsProps {
  deal: Deal;
}

const MagazineSubscriptionsPros: React.FC<MagazineSubscriptionsProsProps> = ({ deal }) => (
  <Consumer>
    {(value): JSX.Element | null => {
      const { data } = value;

      const pros = getProsCons({
        data,
        modelId: deal.model_id,
        type: 'pros',
      });

      if (pros && pros.length > 0) {
        return (
          <div className={styles.container}>
            <ul className={styles.list}>
              <li className={styles.item} key={pros[0]}>
                {pros[0]}
              </li>
            </ul>
          </div>
        );
      }
      return null;
    }}
  </Consumer>
);

export default MagazineSubscriptionsPros;
