import React from 'react';

import type { Deal } from '../../types/Deal';
import { useHawkWidgetContext } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import MagazineSubscriptionsDeal from './MagazineSubscriptionsDeal';
import MagazineSubscriptionsPros from './MagazineSubscriptionsPros';
import styles from './styles/magazinesubscriptions-item.css';

interface MagazineSubscriptionsItemProps {
  deal: Deal;
  deals: Deal[];
}

const MagazineSubscriptionsItem: React.FC<MagazineSubscriptionsItemProps> = ({ deal, deals }) => {
  const {
    genericSharedComponents: { DealImage, DisplayName },
  } = useHawkWidgetContext();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <i className="icon icon-magazine" style={{ height: '35px' }} />
        <DisplayName deal={deal} className={styles['display-name']} />
      </div>
      <ul className={styles.dots}>
        {deals.map((apple) => {
          return (
            <li
              className={deal?.id === apple.id ? styles.black : styles.grey}
              key={deals.indexOf(apple)}
            >
              ●
            </li>
          );
        })}
      </ul>
      <DealImage deal={deal} className="magazinesubscriptions" width={250} height={125} />
      <div className={styles['pros-deal-container']}>
        <MagazineSubscriptionsPros deal={deal} />
        <MagazineSubscriptionsDeal deal={deal} />
      </div>
    </div>
  );
};

export default MagazineSubscriptionsItem;
