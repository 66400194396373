import React from 'react';

import { IRenderWidgetProps } from '../../../assets/modules/renderWidget/model';
import { getWidgetInitialiser } from '../../../sharedModules/getWidgetInitialiser';
import { loadCSS } from '../../../utils/loadCSS';
import { getInitialiser } from '../../modules/getInitialiser';
import getItemsPerRow from '../../modules/getItemsPerRow';
import type { Deal } from '../../types/Deal';
import { GetInitialiserProps, WidgetWrapper } from '../../types/GetInitialiserProps';
import { RequestType, getJSONFn } from '../../types/GetJSON';
import { WidgetInitialiserOptions } from '../../types/WidgetInitialiser';
import type { WidgetProps } from '../../types/WidgetProps';
import { Consumer } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { Scroller } from '../GenericComponents/Scroller/Scroller';

import { MagazineSubscriptionsMainWrapper } from './MagazineSubscriptionsMainWrapper';
import styles from './styles/magazinesubscriptions.css';

loadCSS('magazinesubscriptions');

interface MagazineSubscriptionsProps {
  endpoint: WidgetProps['endpoint'];
  getDealData: WidgetProps['getDealData'];
  dealData: WidgetProps['dealData'];
  getJSON: WidgetProps['getJSON'];
  sendAnalytics: WidgetProps['sendAnalytics'];
  setDealData: WidgetProps['setDealData'];
  translate: WidgetProps['translate'];
  widgetEndpoint: WidgetProps['widgetEndpoint'];
  genericSharedComponents: WidgetProps['genericSharedComponents'];
}

const MagazineSubscriptions: React.FC<MagazineSubscriptionsProps> = (props) => {
  const {
    genericSharedComponents: { HawkWidgetAdvanced },
  } = props;
  return (
    <HawkWidgetAdvanced {...props}>
      <Consumer>
        {(value): JSX.Element => {
          const { dealData, loadMoreDeals } = value;
          const deals = dealData && dealData.deals ? dealData.deals : [];
          const pages = getItemsPerRow<Deal>(deals, 1);

          return (
            <div className={styles.container}>
              <Scroller
                items={deals}
                totalItems={deals.length}
                loadMoreItems={loadMoreDeals}
                pages={pages}
                className="magazinesubscriptions"
                autoShuffle
                pagesLength={pages.length}
              >
                {pages.map((items) => {
                  const key = items.map((item) => item.id).join('|');
                  return <MagazineSubscriptionsMainWrapper items={items} key={key} />;
                })}
              </Scroller>
            </div>
          );
        }}
      </Consumer>
    </HawkWidgetAdvanced>
  );
};

MagazineSubscriptions.getInitialiserProps = async (
  { el, site, articleUrl, ...restRenderProps }: IRenderWidgetProps,
  attributes: DOMStringMap,
  getJSON: getJSONFn<RequestType.WIDGET_REQUEST>,
  editorial: boolean,
  defaultParams: WidgetInitialiserOptions['defaultParams'],
  endpoint: string,
  url: string,
  origin: string,
  dataLinkMerchant: string | null,
): GetInitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type, apiCall } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    endpoint,
    url,
    origin,
    dataLinkMerchant,
    site,
    getJSON,
    placeholder: el,
    articleUrl,
    attributes,
    getInitialiser,
    ...restRenderProps,
  });

  return { props, type, apiCall, widgetWrapper: WidgetWrapper.DIV };
};

export default MagazineSubscriptions;
