import React from 'react';

import styles from './styles/magazinesubscriptions-main.css';

interface IMagazineSubscriptionsMainProps {
  children?: React.ReactNode;
}

const MagazineSubscriptionsMain: React.FC<IMagazineSubscriptionsMainProps> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default MagazineSubscriptionsMain;
