import React from 'react';

import { PromoType, getPromoItems } from '../../../sharedModules/getPromoItems';
import type { Deal } from '../../types/Deal';
import { Consumer } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { Promos } from '../GenericComponents/Promos/loadable';

import styles from './styles/magazinesubscriptions-deal.css';

interface MagazineSubscriptionsDealProps {
  deal: Deal;
}

const MagazineSubscriptionsDeal: React.FC<MagazineSubscriptionsDealProps> = ({ deal }) => {
  const promoItems = getPromoItems(deal, PromoType.ALLOWED, false);
  return (
    <Consumer>
      {(value): JSX.Element => {
        const {
          translate,
          genericSharedComponents: { Button, DisplayPrice },
        } = value;

        return (
          <div className={styles.container}>
            <div className={styles.main}>
              <DisplayPrice
                deal={deal}
                label={translate('promotionDealPricePrefix')}
                className="magazinesubscriptions"
              />
              {promoItems.length > 0 && (
                <Promos items={promoItems} className="magazinesubscriptions" />
              )}
            </div>
            <Button deal={deal} className="magazinesubscriptions-button" />
          </div>
        );
      }}
    </Consumer>
  );
};

export default MagazineSubscriptionsDeal;
